import { render, staticRenderFns } from "./FaqEditor.vue?vue&type=template&id=02802201&scoped=true&"
import script from "./FaqEditor.vue?vue&type=script&lang=js&"
export * from "./FaqEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02802201",
  null
  
)

export default component.exports