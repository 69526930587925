<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onBack" :disabled="loading">뒤로</Button>
      <Button @click="onSubmit" type="primary" :disabled="loading">저장</Button>
    </template>

    <div class="page-content">
      <Form label-position="right" :label-width="120">
        <FormItem label="제목">
          <Input size="large" v-model="form.title"></Input>
        </FormItem>
        <FormItem label="구분">
          <Select size="large" v-model="form.category">
            <Option v-for="(cat, index) in categories" :value="cat.key" :key="`option-${index}`">{{cat.label}}</Option>
          </Select>
        </FormItem>
        <FormItem label="공개 설정">
          <Checkbox size="large" v-model="form.is_open">해당 FAQ를 공개합니다.</Checkbox>
        </FormItem>
        <FormItem label="본문">
          <HtmlEditor v-model="form.body"></HtmlEditor>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
import {editorMixin} from "@/mixins/editor";
import HtmlEditor from "@/components/HtmlEditor";

export default {
  components: {HtmlEditor},
  props: ['id'],
  mixins: [editorMixin],
  computed: {
    apiUrl() {
      if (this.isModify) {
        return `faq/${this.id}`
      } else {
        return `faq`
      }
    }
  },
  data() {
    return {
      form: {
        title: '',
        category: '',
        is_open: false,
        body: '',
      },
      categories: [
        {key: 'cat0', label: '자주 묻는 질문',},
        {key: 'cat1', label: '회원정보',},
        {key: 'cat2', label: '학습/수강',},
        {key: 'cat3', label: '결제/환불',},
        {key: 'cat4', label: '영상재생',},
        {key: 'cat5', label: '강의내용',},
      ],
    }
  },
  mounted() {
    if (this.isModify) {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>